@font-face {
  font-family: "Conthrax";
  font-style: normal;
  font-weight: normal;
  src: url(https://fonts.cdnfonts.com/s/17842/conthrax-sb.woff) format("woff"),
    url("ConthraxSb-Regular.woff2") format("woff2"),
    url("ConthraxSb-Regular.woff") format("woff"),
    url("ConthraxSb-Regular.ttf") format("truetype");
  font-display: swap;
}
